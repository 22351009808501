import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import wx from "weixin-js-sdk"
import request from '@/utils/request'
// import VConsole from 'vconsole'
import Vuex from 'vuex'
import store from './store'

Vue.config.productionTip = false



// const vconsole = new VConsole()

// Vue.use(vconsole)
Vue.use(VueRouter)
Vue.use(Vant)
Vue.use(Vuex)


Vue.prototype.$wx = wx
Vue.prototype.$http = request

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
