import VueRouter from 'vue-router'

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            alias: ['/'],
            name: 'authorize',
            component: () => import('@/views/authorize/index.vue'),
            meta: { title: '用户信息授权页' },
        },
        {
            path: '/lineup',
            name: 'lineup',
            component: () => import('@/views/lineup/index.vue'),
            meta: { title: '在线咨询-等待中' },
        },
        {
            path: '/patient',
            name: 'patient',
            component: () => import('@/views/patient/index.vue'),
            meta: { title: '心理咨询平台' },
        },
        {
            path: '/doctor',
            name: 'doctor',
            component: () => import('@/views/doctor/index.vue'),
            meta: { title: '心理咨询平台-医生端' }
        },

    ]
})

//全局前置路由守卫
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '平台'
    next()
})

export default router