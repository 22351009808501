<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getSdkConfig } from '@/api/wx'
export default {
  name: 'App',
  data() {
    return {
      code: undefined,
      // appid:"wxbee3796f7a140294", //测试
      // redirect_uri:"http%3A%2F%2Fim.lyganyang.cn"
      // redirect_uri:"http%3A%2F%2F192.168.0.110"

      //连云港149医院
      appid:"wx2dad6c1375ec668d", 
      redirect_uri:"http%3A%2F%2F1492.sunrise-worldwide.com"
    }
  },
  methods: {
    //初始化微信jssdk
    async initWxJssdk() {

      //从服务端获取配置信息
      const config = await getSdkConfig({ "code": this.code, "uStr": location.href.split('#')[0] })
      if (config.code !== 200) {
        console.error(' jssdk配置失败 ', config)
        alert(config.msg)
        return
      }

      console.log('获取到的配置信息', config)
      this.$store.dispatch('setOpenId',config.data.openid)
      console.log(' vuex setOpenId: ', this.$store.state.openid)

      //sdk配置
      this.$wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.data.appId, // 必填，公众号的唯一标识
        timestamp: config.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.data.nonceStr, // 必填，生成签名的随机串
        signature: config.data.signature,// 必填，签名
        jsApiList: ["startRecord"] // 必填，需要使用的JS接口列表
      })

      //sdk初始化完成
      this.$wx.ready(() => {
        console.log('微信jssdk初始化完成')
        //检查权限
        this.$wx.checkJsApi({
          jsApiList: ['startRecord'],
          success: res => {
            console.log('检查权限', res)
            //检查权限是否开启
            if (res.checkResult.startRecord === false) {
              alert('请先开启录音权限')
            } else {
              //转入咨询等待页面
              this.$router.push('/lineup')
            }
          }
        })
      })

      //sdk初始化失败
      this.$wx.error(res => {
        console.error('微信jssdk初始化失败', res)
      })

    }
  },
  mounted() {

    //测试转到等待页
/*     this.$store.dispatch("setOpenId","osCS4uMpHZdGeF3t4fbdXoMjiZa8")
    this.$router.push('/lineup').catch(e => { e })
    if(window.location.pathname === "/lineup"){
      return
    } */


    if(window.location.pathname === "/doctor" || window.location.href.indexOf("?doctor=jk") !== -1){
      this.$router.push('/doctor').catch(e => { e })
      return 
    }

    //获取地址栏参数
    let searchParams = window.location.search


    //参数为空，转至微信重新授权
    if (!searchParams) {
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=2024#wechat_redirect`
      return
    }

    //对参数进行格式化处理
    const params = new URLSearchParams(searchParams)

    //提取微信授权code
    const code = params.get('code')
    if (!code) {
      //授权失败
      this.$toast('微信授权失败')
      return
    }
    this.code = code
    //微信sdk初始化
    this.initWxJssdk()
  }
}
</script>

<style></style>
