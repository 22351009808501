import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//用于响应组件中动作
const actions = {
    setOpenId(context, data) {
        console.log(" store actions setOpenId params: ", data)
        context.commit('setOpenId', data)
    }
}

//用户操作数据
const mutations = {
    setOpenId(state, data) {
        console.log(" store mutations setOpenId params: ", data)
        state.openid = data
    }
}

//存储数据
const state = {
    openid: undefined
}

export default new Vuex.Store({
    actions,
    mutations,
    state
})