import axios from 'axios'
//创建一个axios对象
const service = axios.create({
    baseURL: '/api',
    timeout: 1000 * 5,
})


//响应拦截
service.interceptors.response.use(
    (res) => {
        let resData = res.data
        if (res.data.code === 200) {
            return resData
        } else {
            alert(resData.msg)
        }
    },
    (error) => {
        console.log('网络请求响应异常结果：', error)
        if (error.response.data) {
            alert(error.response.data.errors[0].defaultMessage)
        }else{
            return Promise.reject(error)
        }
    }
)


export default service