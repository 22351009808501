import request from '@/utils/request'

//获取公众号信息
export function getSdkConfig(params){
    console.log('接收到的请求参数有：',params)
    return request({
        url: '/wx/getSdkConfig',
        method: 'get',
        params: params
    })
}

//依据OpenId获取微信用户信息
export function getUserInfoByOpenId(openId){
    return request({
        url: '/wx/getUserInfoByOpenId',
        method: 'get',
        params: {"openid": openId}
    })
}